.is-hyba,
.cart-show-directory {
    .reit-hb {
        display: none;
    }
}

.reit-hb {
    $color-grey: #E5E5E5;

    background: $color-grey;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    padding: 1.5rem 0;
    text-align: center;
    text-transform: uppercase;

    &__link {
        text-decoration: underline;
    }
}
